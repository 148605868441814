<script setup lang="ts">
  const props = defineProps<{
    role: string,
  }>()

  const emits = defineEmits<{
    (e: 'cancel'): void,
    (e: 'logout'): void,
  }>()
</script>
<template>
  <common-modal
    @close="emits('cancel')"
    header-text="確認"
    modal-margin-x="sp:mx-4 tab:mx-[30vw]"
    modal-height="h-auto"
    :role="props.role"
  >
    <template
      v-slot:body
    >
      <div
        class="
          common-text-gray
          font-semibold
          text-center
        "
      >
        ログアウトしますか？
      </div>
    </template>
    <template
      v-slot:control
    >
      <div
        class="
          inline-block
          sp:px-2
          tab:px-5
          w-1/2
        "
      >
        <common-btn
          btn-type="tertiary"
          class="
            w-full
          "
          @click="emits('cancel')"
        >
          キャンセル
        </common-btn>
      </div>
      <div
        class="
          inline-block
          sp:px-2
          tab:px-5
          w-1/2
        "
      >
        <common-btn
          :btn-type="props.role == 'manager' ? 'primary' : 'septenary'"
          class="
            w-full
          "
          @click="emits('logout')"
        >
          ログアウト
        </common-btn>
      </div>
    </template>
  </common-modal>
</template>
