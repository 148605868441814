import { Router } from 'vue-router'

export async function historyBack (router: Router) {
  const backPagePath = sessionStorage.getItem('backPagePath')
  const backPageParam = sessionStorage.getItem('backPageParam')
  if (backPagePath != undefined && backPagePath != '') {
    sessionStorage.removeItem('backPagePath')
    sessionStorage.removeItem('backPageParam')
    const routes = router.getRoutes()
    const queryObject = backPageParam != null ? JSON.parse(backPageParam) : {}
    for(const route of routes) {
      if (route.path == backPagePath) {
        await router.push({
          path: route.path,
          query: queryObject
        })
        return
      }
    }
  }
  router.go(-1)
}
