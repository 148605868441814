export function layoutsSetting (isManager: boolean, isForCalc: boolean): string {
  // inset算出用のdiv
  const div = document.createElement('div')
  div.style.paddingTop = 'env(safe-area-inset-top)'
  div.style.paddingBottom = 'env(safe-area-inset-bottom)'

  // DOM追加
  div.style.display = 'none'
  document.body.appendChild(div)

  // スタイル取得
  const style = window.getComputedStyle(div)
  const top = parseInt(style.paddingTop, 10)
  const bottom = parseInt(style.paddingBottom, 10)

  const headerElement = document.getElementsByTagName('header')[0]
  const mainElement = document.getElementsByTagName('main')[0]
  const footerElement = document.getElementsByTagName('footer')[0]

  // 上部にinsetが存在する場合
  if (top > 0) {
    const insetTopElement = document.createElement('div')
    const classList = [
      isManager ? 'common-background-pink' : 'common-background-leaf',
      'fixed',
      'isExistInsetSafeAreaInsetTop',
      'top-0',
      'w-full',
      'z-layouts-header',
    ]
    for (const item of classList) {
      insetTopElement.classList.add(item)
    }
    // header上部に要素を追加してinset分を確保
    document.body.getElementsByClassName('layout')[0].prepend(insetTopElement)
  }

  // 下部にinsetが存在する場合
  if (bottom > 0) {
    // paddingでfooter下部のinset分を確保
    footerElement.classList.add('isExistInsetSafeAreaInsetBottom')
  }

  const height = window.getComputedStyle(headerElement).height

  // mainの高さ確保はinsetの有無に関わらずheader,footerの高さを設定
  if (!isForCalc) {
    mainElement.style.paddingTop = height
    if (footerElement) {
      mainElement.style.paddingBottom = window.getComputedStyle(footerElement).height
    }
  }
  // 算出用のdivを削除
  document.body.removeChild(div)

  // 計算用の場合は追加した要素を削除
  if (isForCalc) {
    if (top > 0) {
      document.getElementsByClassName('isExistInsetSafeAreaInsetTop')[0].remove()
    }
    if (bottom > 0) {
      document.getElementsByTagName('footer')[0].classList.remove('isExistInsetSafeAreaInsetBottom')
    }
  }

  return height
}
